<ev-modal-dialog
  [$title]="title"
  [$subtitle]="subtitle"
  (closeDialog)="onClose()">
  <div class="buttons-wrapper">
    <atom-button secondary (click)="onClose()"
      ><span i18n>Cancel</span></atom-button
    >
    <atom-button (click)="onConfirm()" data-cy="confirm-button">{{
      confirmButtonText
    }}</atom-button>
  </div>
</ev-modal-dialog>
